import api from '../../../utils/api';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { getParentDomain } from '../../../utils/utils';
import { IBLSpinLoader, Error } from '@iblai/ibl-web-react-common';

const DELAY_RETRY = 2000;

const CompleteLogin = ({ isMobileApp }) => {
  const MAX_RETRY = 10;
  const location = useLocation();
  const [isError, setIsError] = useState(false);
  const [retry, setRetry] = useState(0);
  const intervalId = useRef(null);

  useEffect(() => {
    if (isMobileApp) {
      // return to mobile app with the authorization code
      const queryParams = new URLSearchParams(location.search);
      window.location.href = `ibl-apps://${
        window.location.hostname
      }?code=${queryParams.get('code')}`;
    } else {
      api.ibledxtenants.getUserTenants(
        (tenants) => {
          const selectedTenant = localStorage.getItem('selected_tenant');

          api.iblutils.saveUserTenantsDataToLocalStorage(
            tenants,
            selectedTenant
          );
          if (tenants.length) {
            const formData = new FormData();

            formData.append('platform_key', localStorage.getItem('tenant'));
            api.ibldmauth.getToken(
              formData,
              ({ data }) => {
                api.iblwebauth.initializeLocalStorageWithAuthData(
                  data.axd_token,
                  data.dm_token,
                  data.user,
                  tenants,
                  localStorage.getItem('tenant')
                );
                const redirectTo = localStorage.getItem('redirectTo');
                if (redirectTo) {
                  document.cookie = `userData=; Max-Age=0; path=/; Domain=${getParentDomain(
                    window.location.hostname
                  )}`;
                  document.cookie = `userData=${encodeURIComponent(
                    localStorage.userData
                  )}; domain=${getParentDomain(
                    window.location.hostname
                  )}; path=/`;
                  window.location.href = `${redirectTo}/sso-login?data=${encodeURIComponent(
                    JSON.stringify({ ...localStorage })
                  )}`;
                }
              },
              (_) => {
                const _errorMsg =
                  "We couldn't fetch your tokens. Please contact administrator";
                console.error(_errorMsg);
                errorCallback();
              }
            );
          } else {
            const _errorMsg =
              'Your account is not associated with any tenant. Please contact adminstration';
            console.error(_errorMsg);
            errorCallback();
          }
        },
        (_) => {
          const _errorMsg =
            "We couldn't fetch your tenants. Please contact adminstrator";
          console.error(_errorMsg);
          errorCallback();
        }
      );
    }
  }, [retry]);

  const errorCallback = () => {
    if (intervalId.current) {
      clearInterval(intervalId);
    }
    if (retry > MAX_RETRY) {
      setIsError(true);
    } else {
      intervalId.current = setTimeout(() => {
        setRetry(retry + 1);
      }, DELAY_RETRY);
    }
  };

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {!isError ? (
          <IBLSpinLoader size={40} containerHeight={'100px'} />
        ) : (
          <Error
            errorCode={500}
            header="Can't authenticate you"
            message="We are sorry, we can't verify your identity"
            subMessage="If you believe this is a mistake, please message at"
          >
            <a
              href={'mailto:' + process.env.REACT_APP_IBL_SUPPORT_EMAIL}
              style={{ textDecoration: 'none' }}
            >
              &nbsp;{process.env.REACT_APP_IBL_SUPPORT_EMAIL}
            </a>
          </Error>
        )}
      </div>
    </>
  );
};

export { CompleteLogin };
