"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tracking = void 0;
const track_1 = require("./ibl-axd/track");
const utils_1 = require("./utils");
class Tracking {
    constructor(config) {
        this.INTERVAL = 30000; // 30 seconds in milliseconds
        this.INACTIVITY_THRESHOLD = 60000; // 1 minute in milliseconds
        this.MIN_TRACKING_DURATION = 5000; // 5 seconds in milliseconds
        this.INTERVAL = config.INTERVAL;
        this.INACTIVITY_THRESHOLD = config.INACTIVITY_THRESHOLD;
        this.MIN_TRACKING_DURATION = config.MIN_TRACKING_DURATION;
        this.lastActivityTime = Date.now();
        this.trackingStartTime = Date.now();
        this.intervalId = null;
        this.totalActiveTime = 0;
        this.isTracking = false;
        if (!(0, utils_1.isInIframe)() && localStorage.getItem('tenant')) {
            // Initialize event listeners
            this.initializeEventListeners();
            // Start tracking when the instance is created
            this.startTracking();
        }
    }
    static getInstance(config = {
        INTERVAL: 30000,
        INACTIVITY_THRESHOLD: 60000,
        MIN_TRACKING_DURATION: 5000,
    }) {
        if (!Tracking.instance) {
            Tracking.instance = new Tracking(config);
        }
        return Tracking.instance;
    }
    makeApiCall() {
        const currentTime = Date.now();
        const sessionDuration = currentTime - this.trackingStartTime;
        const activeTime = Math.min(currentTime - this.lastActivityTime, this.INTERVAL);
        this.totalActiveTime += activeTime;
        const payload = {
            timestamp: new Date().toISOString(),
            url: this.url,
            count: Math.round(sessionDuration / 1000),
            org: localStorage.getItem('tenant'),
        };
        if ((0, utils_1.isCourseInnerware)()) {
            const courseId = (0, utils_1.getCourseIdFromURL)();
            if (courseId) {
                payload['course_id'] = courseId;
            }
            const unitId = (0, utils_1.getUnitFromCourseInnerwareURL)();
            if (unitId) {
                payload['block_id'] = unitId;
            }
        }
        console.log('Payload is ', payload);
        (0, track_1.timeUpdate)(payload, (data) => {
            console.log('Response to time track update ', data);
        });
        // Replace this with your actual API call
        console.log('Making API call at:', new Date().toISOString());
        console.log('Session duration:', sessionDuration);
        console.log('Total active time:', this.totalActiveTime);
        this.trackingStartTime = Date.now();
        // Reset tracking if it exceeds the maximum duration
        // if (sessionDuration > this.MAX_TRACKING_DURATION) {
        //   this.stopTracking();
        //   this.startTracking();
        // }
    }
    resetTimer() {
        this.lastActivityTime = Date.now();
    }
    startTracking() {
        if (!this.isTracking) {
            this.url = window.location.href;
            this.isTracking = true;
            this.trackingStartTime = Date.now();
            this.totalActiveTime = 0;
            this.resetTimer();
            this.intervalId = setInterval(() => this.checkActivityAndMakeCall(), this.INTERVAL);
        }
    }
    stopTracking() {
        if (this.isTracking) {
            this.isTracking = false;
            if (this.intervalId !== null) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
            const trackingDuration = Date.now() - this.trackingStartTime;
            if (trackingDuration >= this.MIN_TRACKING_DURATION) {
                this.makeApiCall(); // Final call before stopping
            }
        }
    }
    checkActivityAndMakeCall() {
        const currentTime = Date.now();
        if (currentTime - this.lastActivityTime < this.INACTIVITY_THRESHOLD) {
            this.makeApiCall();
        }
        else {
            this.trackingStartTime = Date.now();
        }
    }
    handleUserActivity() {
        if (!this.isTracking) {
            this.startTracking();
        }
        this.resetTimer();
    }
    handleVisibilityChange() {
        if (document.hidden) {
            this.stopTracking();
        }
        else {
            this.startTracking();
        }
    }
    initializeEventListeners() {
        [
            'mousemove',
            'keydown',
            'click',
            'scroll',
            'touchstart',
            'touchmove',
        ].forEach((eventType) => {
            document.addEventListener(eventType, () => this.handleUserActivity());
        });
        document.addEventListener('visibilitychange', () => this.handleVisibilityChange());
        window.addEventListener('focus', () => this.startTracking());
        window.addEventListener('blur', () => this.stopTracking());
        window.addEventListener('online', () => this.startTracking());
        window.addEventListener('offline', () => this.stopTracking());
    }
    reportUrlChangeForTracking() {
        if (!(0, utils_1.isInIframe)() && localStorage.getItem('tenant')) {
            this.stopTracking();
            this.startTracking();
        }
    }
}
exports.Tracking = Tracking;
