import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearCookies } from '@iblai/ibl-web-react-common';
const Logout = () => {
  const location = useLocation();
  const handleLogout = (redirectTo) => {
    fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout`, {
      method: 'GET',
      credentials: 'include',
    }).then(() => {
      localStorage.clear();
      clearCookies();
      window.location.href = redirectTo;
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirect-to');
    handleLogout(redirectTo);
  }, []);
};

export { Logout };
